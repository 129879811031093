
.meeting-container {
    width: 100%;
    height: 600px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    .meeting-btn {
        z-index: 10;
    }
}

#zmmtg-root {
    position: absolute !important;
    height: 100%;
    width: 100%;

    opacity: 0;

    .meeting-app {
        position: relative;
        height: 100%;
        width: 100%;

        .meeting-client {
            position: absolute;
        }

        .meeting-client-inner {
            position: absolute;
        }
    }

    #dialog-join {
        width: 100%;
        height: 100%;

        position: absolute;
        top: 0;
        left: 0;

        background-color: opacify(map_get($foundation-palette, 'primary'), 0.2);
    }

    transition: opacity .5s ease-out;

    &.loaded {
        opacity: 1;
    }
}

#meeting-modal {

}

.join-meeting__tech-grid {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;

    flex-wrap: wrap;

    @include breakpoint(large) {
        flex-wrap: nowrap;
    }
}

.tech-card {

    width: 100%;

    @include breakpoint(medium) {
        width: 40%;
        margin: 1rem 2rem;
    }

    @include breakpoint(large) {
        width: 33%;
    }


    @include breakpoint(xlarge) {
        width: 33%;
        margin: 0 4rem;
    }

    text-align: center;

    &__title {
        font-weight: bold;
    }

    & h4 {
        font-size: 1.3rem;
    }

    &__logo {

        padding: $global-padding;

        p {
            font-size: 1rem;
            line-height: 1.15;
        }

        img {
            margin: 0 1rem 1rem 0;
            max-width: 25%;

            @include breakpoint(large) {
                max-width: 33%;
            }

            @include breakpoint(xlarge) {
                max-width: 40%;
            }
        }

    }

    &__content {
        display: flex;
        flex-direction: column;
        align-content: flex-start;
        &__info {
            flex: 1;
            font-size: 0.9rem;
        }
        &__action {

        }
    }
}
