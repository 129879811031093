$min_width: 320px;
$max_width: $global-width;
$min_font_ratio: 1.61803398875;
$min_font: $global-font-size / $min_font_ratio;
$max_font: $global-font-size * $min_font_ratio;

$header-sizes: (
    small: (
        'h1': 22,
        'h2': 18,
        'h3': 17,
        'h4': 16,
        'h5': 15,
        'h6': 14,
    ),
    medium: (
        'h1': 36,
        'h2': 29,
        'h3': 22,
        'h4': 18,
        'h5': 16,
        'h6': 14,
    ),
    large: (
        'h1': 32,
        'h2': 26,
        'h3': 22,
        'h4': 18,
        'h5': 16,
        'h6': 14,
    ),
    xlarge: (
        'h1': 32,
        'h2': 26,
        'h3': 22,
        'h4': 18,
        'h5': 16,
        'h6': 14,
    ),
    xxlarge: (
        'h1': 32,
        'h2': 26,
        'h3': 22,
        'h4': 18,
        'h5': 16,
        'h6': 14,
    ),
);

@function strip-unit($value) {
    @return $value / ($value * 0 + 1);
}

@mixin fluid-type($min-vw, $max-vw, $min-font-size, $max-font-size) {
    $u1: unit($min-vw);
    $u2: unit($max-vw);
    $u3: unit($min-font-size);
    $u4: unit($max-font-size);

    @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
        & {
            font-size: $min-font-size;
            @media screen and (min-width: $min-vw) {
                font-size: calc(#{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
            }
            @media screen and (min-width: $max-vw) {
                font-size: $max-font-size;
            }
        }
    }
}

html {
    @include fluid-type($min-width, map-get($breakpoints, 'xxlarge'), $min_font, $max_font);
}

@each $breakpoint, $sizes in $header-sizes {
    @include breakpoint($breakpoint) {
        @if $breakpoint != "small" {
            @each $heading, $font-size in $sizes {
                #{$heading} {
                    @include fluid-type($min-width, map-get($breakpoints, $breakpoint), $font-size / $min_font_ratio, $font-size);
                }
            }
        }
    }
}
