$hero-full-screen-bg: url('/themes/oct-foundation/assets/img/cardio-symposium-header.jpg') center center no-repeat;
$hero-full-screen-bg-size: cover;
$hero-full-screen-text-color: $primary-color;

.hero-full-screen {
    height: auto;

    @include breakpoint(medium) {
        height: 100vh;
    }

    @media screen and #{breakpoint(medium)} and #{breakpoint(landscape)} {
        height: auto;
    }

    @media screen and #{breakpoint(large)} and #{breakpoint(landscape)} {
        height: 100vh;
    }

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background: $hero-full-screen-bg;
    background-size: $hero-full-screen-bg-size;

    overflow: hidden;

    .middle-content-section {

        width: 100%;
        padding: $global-padding * 2 0;

        text-align: center;


        &__container {

            padding: $global-padding * 2 0;
            background: transparentize($white, 0.25);

            text-align: left;

            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            justify-content: space-between;

            margin-bottom: $global-margin * 2;
        }

        &__content {
            @include xy-grid-container();

            h1, h2, h3 {
                line-height: 1.15;
                padding-bottom: 1rem;
                font-weight: $global-weight-normal;
                small {
                    display: block;
                    margin-top: .75rem;
                    color: $primary-color;
                    font-weight: $global-weight-normal;
                }
            }

            .subheader {
                padding-top: 1rem;
                margin-bottom: 0;
                color: $secondary-color;
                @include fluid-type(320px, 1440px, 18px, 34px);
            }

            h1 {
                font-weight: $header-font-weight;
                @include fluid-type(320px, 1440px, 28px, 46px);
                color: $primary-color;
                letter-spacing: -1px;
            }

            .event-dates {
                color: $light-blue;
                font-weight: $global-weight-bold;
                @include fluid-type(320px, 1440px, 16px, 28px);

                @include breakpoint(medium) {
                    padding-top: 2rem;
                }
            }
        }
    }

    .top-content-section {
        width: 100%;
        background: $white;

    }

    .bottom-content-section {
        padding: 1rem;

        svg {
            height: rem-calc(60);
            width: rem-calc(60);
            fill: $hero-full-screen-text-color;
        }
    }

}
