@charset "utf-8";

@import "_settings";
@import "_foundation_components";

//@import "components/fontawesome-icons";

// vendor styles
// @import "vendor/aos";
// @import "vendor/slick-carousel";
// @import "vendor/index-menu";

@import "components/fluid_type";
@import "components/meeting";


@mixin fancy-boxshadow($inset: 'inset') {
    box-shadow: #{$inset} 0 1px 1px rgba(0, 0, 0, 0.12),
    #{$inset} 0 2px 2px rgba(0, 0, 0, 0.12),
    #{$inset} 0 4px 4px rgba(0, 0, 0, 0.12),
    #{$inset} 0 8px 8px rgba(0, 0, 0, 0.12),
    #{$inset} 0 16px 16px rgba(0, 0, 0, 0.12);
}

html, body {
    height: 100%;
}

body {
    display: flex;
    flex-direction: column;
}

.content {
    flex: 1 0 auto;
}

#main-content-section {

}

.main-footer {
    flex-shrink: 0;

    background: $light-blue;
    padding: 2rem 0;

    color: $white;

    a {
        color: $white;
        text-decoration: underline;
    }
}

.hero-menu {
    @include xy-grid-container();

    display: flex;
    justify-content: space-between;

    li > a.button.expanded {
        margin-bottom: .5rem;
    }

    @include breakpoint(large) {
        width: 895px;

        li > a.button {
            width: 264px;
            font-size: map-get($button-sizes, large);
        }
    }
}

.page-content {
    //background-image: linear-gradient(43deg, rgb(8, 80, 120) 0%, rgb(255, 255, 255) 40%, rgb(255, 255, 255) 60%, rgb(175, 202, 11) 100%);

    .section-block {
        padding-top: 4rem;
        background: transparentize($white, .25);

        &__content {
            @include xy-grid-container($padding: 4rem);
            padding-bottom: 2rem;
        }

        h3 {
            position: relative;
            left: 0;
            display: block;
            background: linear-gradient(to right, $light-blue 80%, transparent);
            padding: .25rem;
            color: $white;
            margin-left: -100%;
            padding-left: 100%;

            margin-bottom: 2rem;
        }
    }
}

//REGISTRATION
.card {
    &__registration {
        .card-divider {
            padding: .5rem;
            font-weight: $global-weight-bold;
            background: $primary-color;
            color: $white;
            text-align: center;
            justify-content: center;
        }

        .card-section {
            background: $white;
            text-align: center;
            padding: 0;

            p {
                margin: 0;
            }

            .label {
                display: block;
                margin: 0;
                padding: .5rem;
            }

            .button {
                @include button-expand();
                margin: 0;
                font-weight: $global-weight-bold;
            }
        }
    }
}

.callout.registration-callout {
    background: transparentize($primary-color, 0.85);;
}

.allgemeine-info {
    padding: 1rem 0 4rem 0;
}


.schedule {
    display: grid;
    grid-template-columns: [time-slot] minmax(30px, 150px) [session-title] 2fr [referents] 1fr [separator] auto;
    align-items: start;

    .time-slot, .title h4 {
        @include fluid-type(320px, 1440px, 16px, 21px);
        line-height: 1.45;
        margin: 0;
    }

    .time-slot {
        color: $light-blue;
    }

    .referents {
        p {
            padding-top: 2rem;
            margin: 0;
        }
    }

    .separator {
        grid-column: 1 / -1;
        border-bottom: 1px solid get-color(primary);
        height: 1px;
    }

    & > div:not(.separator), & > h4 {
        padding: $global-padding;
    }

    & > div.time-slot {
        display: flex;
        justify-content: space-between;
        padding-left: 0;
    }

    .title {
        line-height: 1.15;
        font-weight: $global-weight-bold;
        margin: 0;
        padding-top: 1.25rem;

        span.breakout-title {
            color: $secondary-color;
        }
    }

    .referents {
        height: 100%;
        font-weight: $global-weight-bold;
        @include fluid-type(320px, 1440px, 14px, 18px);
        line-height: 1.65;
        margin: 0;

        h5 {
            @include fluid-type(320px, 1440px, 14px, 16px);
            margin-bottom: .5rem;
        }
    }

    &__overview {
        .time-slot, .title h4 {
            margin: 1.75rem 0 0 0;
        }

        .referents {
            background: transparentize($primary-color, 0.85);

            &__alt {
                background: transparentize($secondary-color, 0.85);
            }
        }
    }
}

h5.secondary {
    color: $secondary-color;
}

.page-content .section-block.section-block__referenten {
    strong {
        color: $primary-color;
        font-size: 1.2rem;
    }
}

.page-content .section-block.section-block__supporter {
    background: $white;

    margin-top: 4rem;
    padding-top: 0;
    padding-bottom: 300px;

    .supporter-row {
        width: fit-content;
        display: flex;
        align-items: center;
        justify-content: center;

        flex-wrap: wrap;

        .supporter-logo {
            padding-left: 2rem;
            padding-right: 2rem;

            margin-bottom: 2rem;

            img {
                width: auto;
                height: 40px;
            }

            @include breakpoint(large) {
                border-right: 1px solid $light-gray;
                justify-content: space-between;
                margin-bottom: 0;


                &:first-child {
                    padding-left: 0;
                }
                &:last-child {
                    border-right: none;
                }

            }
        }
    }
}

.layout-default .page-content {
    height: 100%;
    padding-top: $global-padding * 2;

    background: $white;
}

.layout-webinar .page-content {
    h2 small {
        color: $secondary-color;
    }
}

.form-group__events {
    label {
        font-size: 1rem;
        margin-bottom: .75rem;
    }
}

.form-group {
    label {
        font-weight: $global-weight-bold;
    }
    &__checkboxes,
    &__radio {
        label {
            font-weight: $global-weight-normal;
            display: flex;
            align-content: flex-start;
            align-items: flex-start;

            width: 100%;

            input, span {
                line-height: 1.15;
            }
        }
    }

    &__2-columns {
        &-container {
            display: flex;
            flex-wrap: wrap;
            label {
                width: 50%;
            }
        }
    }
}

// import components
@import "components/fullscreen_hero";

.main-header, .hero-full-screen {

    .top-bar {
        background: $white;

        &.is-stuck {
            @include fancy-boxshadow($inset: null);
        }

        .menu {
            background: transparent;
        }

        .menu-text {
            color: $hero-full-screen-text-color;
            padding-left: 0;
            margin-right: 0;

            img {
                width: 140px;
            }

            @media screen and #{breakpoint(medium)} and #{breakpoint(landscape)} {
                width: 100%;
            }

            @media screen and #{breakpoint(large)} and #{breakpoint(landscape)} {
                width: auto;
            }

            @include breakpoint(xlarge) {
                margin-right: 2rem;
                img {
                    width: 180px;
                }
            }

            @include breakpoint(xxlarge) {
                margin-right: 4rem;
                img {
                    width: auto;
                }
            }

        }

        .menu li {
            display: flex;
            align-items: center;
        }

        .menu a {
            color: $hero-full-screen-text-color;
            font-size: 1.1rem;
            text-transform: uppercase;

            padding: 0.7rem .5rem;

            @include breakpoint(xlarge) {
                padding: 0.7rem .7rem;
            }

            &.is-active {
                text-decoration: underline;
            }

            @media screen and #{breakpoint(medium)} and #{breakpoint(portrait)} {
                font-size: 1rem;
            }

            @media screen and #{breakpoint(large)} and #{breakpoint(portrait)} {
                font-size: 1.1rem;
            }
        }

        .top-bar-right {
            width: 200px;

            @include breakpoint(xlarge) {
                width: 300px;
            }

            @include breakpoint(xxlarge) {
                width: auto;
            }

            @media screen and #{breakpoint(medium)} and #{breakpoint(landscape)} {
                display: none;
            }

            @media screen and #{breakpoint(large)} and #{breakpoint(landscape)} {
                display: block;
            }

            @media screen and #{breakpoint(medium)} and #{breakpoint(portrait)} {
                display: none;
            }

            @media screen and #{breakpoint(large)} and #{breakpoint(portrait)} {
                display: block;
            }

            h3 {
                font-weight: $header-font-weight;

                small {
                    display: block;
                    color: $primary-color;
                    font-weight: $global-weight-normal;
                    line-height: 1.1;
                }

                @media screen and #{breakpoint(medium)} and #{breakpoint(portrait)} {
                    display: none;
                }

                @media screen and #{breakpoint(medium)} and #{breakpoint(landscape)} {
                    display: none;
                }

                @media screen and #{breakpoint(large)} and #{breakpoint(landscape)} {
                    display: block;
                }

                @media screen and #{breakpoint(large)} and #{breakpoint(portrait)} {
                    display: block;
                }

                @include breakpoint(large) {
                    font-size: 14px;
                }

                @include breakpoint(xlarge) {
                    @include fluid-type(320px, 1440px, 12px, 21px);
                    padding-right: $global-padding;
                }
            }
        }
    }
}

.main-header .top-bar {
    @include fancy-boxshadow($inset: null);
}

.no-js {
    @include breakpoint(small only) {
        .top-bar {
            display: none;
        }
    }

    @include breakpoint(medium) {
        .title-bar {
            display: none;
        }
    }
}

.location-container {
    margin-top: 2.5rem;
    padding-bottom: 4rem;

    &__card {
        background: transparentize($primary-color, 0.85);
        padding: 1rem;
    }
}

.content-lead {
    @include fluid-type(320px, 1440px, 20px, 24px);
    padding-bottom: 2rem;
}

//REFERENTEN
.referents-container {
    padding-bottom: 4rem;

    &__spacer {
        padding-top: 1rem;
    }
}
